import React from 'react';
import VacancyList from './VacancyList';

export default class VacancyListMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeList: 'active',
            activeJobList: [],
            inactiveJobList: [],
            draftsJobList: [],
            searchJobList: [],
            currentPage: 1,
            activeTotalPages: 1,
            inactiveTotalPages: 1,
            draftsTotalPages: 1,
            searchTotalPages: 1,
            isLoading: false,
            searchTerm: ''
        };
        this.loaderRef = null;
        this.observer = null;
    }

    componentDidMount() {
        this.onMenuItemClick('active');
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activeList !== this.state.activeList) {
            this.createObserver();
        }

        if (prevState[this.state.activeList + 'JobList'] !== this.state[this.state.activeList + 'JobList']) {
            this.createObserver();
        }
    }

    createObserver = () => {
        if (this.observer) this.observer.disconnect();

        if (this.loaderRef) {
            this.observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting && !this.state.isLoading) {
                        this.loadMoreJobs();
                    }
                },
                { root: null, rootMargin: '100px', threshold: 1.0 }
            );
            this.observer.observe(this.loaderRef);
        }
    };

    onMenuItemClick = (list) => {
        this.setState(
            {
                activeList: list,
                currentPage: 1,
                [`${list}JobList`]: []
            },
            () => {
                if (list === "search" && this.searchInput) {
                    this.searchInput.focus();
                }
                this.fetchVacancies(list, 1)
            }
        );
    };

    fetchVacancies = (list, page) => {
        if (this.state.isLoading) return;
        if (list == 'search' && this.state.searchTerm === '') return;

        this.setState({ isLoading: true });

        const { searchTerm } = this.state;

        // Include search term in the API request
        const searchParam = searchTerm ? `&search=${encodeURIComponent(searchTerm)}` : '';

        fetch(`/vacancies?billing_company_id=${this.props.companyId}&filter=${list}&page=${page}${searchParam}`)
            .then((data) => data.json())
            .then((json) => {
                if (list == 'search') {
                    // for search, replace displayed list with newly fetched
                    this.setState((prevState) => ({
                        [`${list}JobList`]: json.items,
                        [`${list}TotalPages`]: json.last_page,
                        currentPage: json.current_page,
                        isLoading: false,
                    }));
                } else {
                    this.setState((prevState) => ({
                        [`${list}JobList`]: [...prevState[`${list}JobList`], ...json.items],
                        [`${list}TotalPages`]: json.last_page,
                        currentPage: json.current_page,
                        isLoading: false,
                    }));
                }

            })
            .catch(() => {
                console.error('Failed to load data');
                this.setState({ isLoading: false });
            });
    };

    loadMoreJobs = () => {
        const { activeList, currentPage } = this.state;
        const totalPages = this.state[`${activeList}TotalPages`];

        if (currentPage < totalPages) {
            this.fetchVacancies(activeList, currentPage + 1);
        }
    };

    handleSearchChange = (event) => {
        this.setState({ searchTerm: event.target.value });
    };

    handleKeyPress = (event) => {
        // Trigger search when "ENTER" is pressed
        if (event.key === 'Enter') {
            this.fetchVacancies('search', 1); // Reset to page 1
        }
    };

    handleSearchClick = () => {
        // Trigger search when search icon is clicked
        this.fetchVacancies('search', 1); // Reset to page 1
    };

    render() {
        const { activeList, currentPage, searchTerm } = this.state;
        const totalPages = this.state[`${activeList}TotalPages`];

        return (
            <section>
                <div className="row menu">
                    <div className={`column menu__item ${activeList === 'active' && 'current'}`} onClick={() => this.onMenuItemClick('active')}>
                        Active Vacancies ({this.props.vacancyCounts.active})
                    </div>
                    <div className={`column menu__item ${activeList === 'inactive' && 'current'}`} onClick={() => this.onMenuItemClick('inactive')}>
                        Inactive Vacancies ({this.props.vacancyCounts.inactive})
                    </div>
                    <div className={`column menu__item ${activeList === 'drafts' && 'current'}`} onClick={() => this.onMenuItemClick('drafts')}>
                        Drafts ({this.props.vacancyCounts.drafts})
                    </div>
                    <div className={`column menu__item ${activeList === 'search' && 'current'}`} onClick={() => this.onMenuItemClick('search')}>
                        Search...
                    </div>
                </div>

                {/* Search Box */}
                {activeList === 'search' && (
                    <div className="search-box">
                        <input
                            ref={(input) => (this.searchInput = input)}
                            type="text"
                            value={searchTerm}
                            onChange={this.handleSearchChange} // Only update searchTerm when typing
                            onKeyDown={this.handleKeyPress} // Only trigger fetch on "ENTER"
                            placeholder="Search vacancies..."
                            maxLength="100"
                            className="search-input"
                        />
                        <button onClick={this.handleSearchClick} className="search-button">
                            <i className="fa fa-search"></i> {/* FontAwesome Search Icon */}
                        </button>
                    </div>
                )}

                {/* Vacancy List */}
                <VacancyList
                    companyId={this.props.companyId}
                    companyType={this.props.companyType}
                    activeList={activeList}
                    jobList={this.state[`${activeList}JobList`]}
                />


                {/* Infinite Scroll Loader */}
                {currentPage < totalPages && (
                    <div
                        ref={(el) => {
                            this.loaderRef = el;
                            this.createObserver();
                        }}
                        style={{ textAlign: 'center', padding: '20px' }}
                    >
                        Loading more vacancies...
                    </div>
                )}
            </section>
        );
    }
}
